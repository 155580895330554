<template>
    <div class="card-pedido dentro col-11 px-0 tres-puntos f-15 py-2">
        <div class="row mx-2 align-items-center">
            <div class="col-6 d-middle px-2 text-general">
                <div class="text-center mr-2" style="width:18px;">
                    <i class="f-20" :class="data.manual === 0 ? iconPedido(data.estado) : 'icon-storefront text-pink'" />                
                    <!-- <i class="icon-pedidos f-15 text-general" /> -->
                </div>
                {{ identifyState(data.estado) }}
            </div>
            <div class="col text-left px-2 f-600">
                <p class="text-general">
                    <i class="icon-product f-15" />
                    {{ convertMoneyTendero(data.valor_final, data.idm_moneda) }} 
                    
                    <span class="mr-1 text-general">
                        ({{ data.cant_productos }}) 
                    </span>
                </p>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col-6 d-flex px-2">
                <div style="width:24px;" />
                <p class="tres-puntos f-600 text-general">No. {{ data.id }}</p>
            </div>
            <div v-if="data.pedido_directo" class="col px-2 text-left text-general">
                <i class="icon-cedis f-14" />
                <span class="ml-1">
                    Gestión CEDIS
                </span>
            </div>
        </div>
        <div class="row mx-2 align-items-center mt-2 border-top py-2">
            <img :src="data.foto" class="obj-cover rounded-circle" width="46" height="46" />
            <div class="col px-2">
                <p class="text-grey f-14">{{ data.nombre ? data.nombre : 'No identificado' }}</p>
                <p class=" text-grey f-14 tres-puntos">{{ data.entrega_fecha | helper-fecha('DD MMMM') }}  {{ data.entrega_horario }}:00</p>
            </div>
            <div v-if="data.cant_mensajes > 0" class="pt-2">
                <el-badge :value="data.cant_mensajes" class="item mx-2 mr-4">
                    <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                </el-badge>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data(){
        return {
            iconPedido(tipo){
                switch (tipo){
                case 110:
                case 11:
                    return 'icon-playlist-edit'
                case 13:
                    return 'icon-account-edit '
                case 12:
                    return 'icon-account-check'
                case 5:
                    return 'icon-storefront'
                case 31:
                    return 'icon-attention-alt'
                default:
                    return ''
                }
            },

            identifyState(state){
                switch (state){
                case 1:
                    return 'Creado'
                case 2:
                    return 'Confirmado'
                case 3:
                    return 'Enviado'
                case 11:
                    return 'Ajustes de tienda'
                case 12:
                    return 'Ajustes confirmados'
                case 13:
                    return 'Confirmado con cambios'
                case 31:
                    return 'Reclamo'
                case 110:
                    return 'Ajustes tienda'
                case 201:
                    return 'Alistando'
                case 202:
                    return 'Empacado'
                default:
                    return '---'
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card-pedido{
    min-height: 130px;
    box-shadow: 0px 2px 4px #00000014;
    border-radius: 10px;
}
</style>
